import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function TechnologyPage() {
    const [activeTab, setActiveTab] = useState('overview');
    const [currentVideoIndex, setCurrentVideoIndex] = useState({});
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState({});
    const [showPlayButton, setShowPlayButton] = useState({});
    const videoRefs = useRef({});
    const playButtonTimeoutRef = useRef({});

    const technologies = [
        {
            id: 'satellite',
            name: "Satellite Imagery and NDVI",
            description: "Satellite technology offers wide-area coverage and frequent global observations. The Normalized Difference Vegetation Index (NDVI) derived from satellite data is a powerful tool for assessing vegetation health, density, and changes over time across large areas.",
            keyPoints: [
                "Provides broad coverage of large areas",
                "Enables regular monitoring and change detection",
                "NDVI assesses vegetation health and density",
                "Useful for agriculture, forestry, and climate studies"
            ],
            image: "Diagram RS.png",
            videos: ["section2.mp4", "section3.mp4", "section4.mp4"]
        },
        {
            id: 'lidar',
            name: "LiDAR",
            description: "LiDAR (Light Detection and Ranging) uses laser pulses to create precise 3D maps of terrain and structures. Its ability to penetrate vegetation and provide highly accurate measurements makes it invaluable for detailed surveying and environmental monitoring.",
            keyPoints: [
                "Creates detailed 3D terrain models",
                "Penetrates vegetation for ground-level data",
                "Highly accurate distance measurements",
                "Useful for forestry, urban planning, and archaeology"
            ],
            image: "diagram 2 LiDAR.png",
            videos: ["section1 - Lidar (1).mp4", "section2 - Lidar (1).mp4", "section3 - Lidar(1).mp4"]
        },
        {
            id: 'drone',
            name: "Drone Scanning",
            description: "Drone technology revolutionizes data collection by providing high-resolution aerial imagery and 3D mapping capabilities. These unmanned aerial vehicles offer flexibility and cost-effectiveness for various surveying and inspection tasks.",
            keyPoints: [
                "Captures high-resolution aerial imagery",
                "Enables quick and frequent data collection",
                "Accesses hard-to-reach or dangerous areas",
                "Cost-effective for small to medium area surveys"
            ],
            image: "diagram drone.png",
            videos: ["section1.mp4"]
        },
        {
            id: 'edna',
            name: "Soil Samples and eDNA",
            description: "Soil sampling and environmental DNA (eDNA) analysis provide crucial insights into soil composition, nutrient levels, and biodiversity. These methods are essential for understanding ecosystem health and guiding sustainable land management practices.",
            keyPoints: [
                "Analyzes soil composition and nutrient levels",
                "Detects presence of organisms through DNA traces",
                "Assesses soil health and biodiversity",
                "Informs agricultural and conservation decisions"
            ],
            image: "diagram eDNA-02.png",
            videos: ["eDNA.mp4"]
        }
    ];

    useEffect(() => {
        technologies.forEach(tech => {
            setCurrentVideoIndex(prev => ({ ...prev, [tech.id]: 0 }));
            setIsVideoPlaying(prev => ({ ...prev, [tech.id]: false }));
            setShowPlayButton(prev => ({ ...prev, [tech.id]: true }));
        });
    }, []);

    useEffect(() => {
        if (activeTab !== 'overview') {
            const tech = technologies.find(t => t.id === activeTab);
            if (tech && videoRefs.current[tech.id] && videoRefs.current[tech.id][currentVideoIndex[tech.id]]) {
                const video = videoRefs.current[tech.id][currentVideoIndex[tech.id]];
                if (isVideoPlaying[tech.id]) {
                    video.play().catch(e => console.error("Error playing video:", e));
                } else {
                    video.pause();
                }
            }
        }
    }, [activeTab, currentVideoIndex, isVideoPlaying]);

    const togglePlayPause = (techId) => {
        setIsVideoPlaying(prev => {
            const newState = { ...prev, [techId]: !prev[techId] };
            return newState;
        });
        setShowPlayButton(prev => ({ ...prev, [techId]: false }));
        
        if (playButtonTimeoutRef.current[techId]) {
            clearTimeout(playButtonTimeoutRef.current[techId]);
        }
        
        playButtonTimeoutRef.current[techId] = setTimeout(() => {
            setShowPlayButton(prev => ({ ...prev, [techId]: !isVideoPlaying[techId] }));
        }, 2000);
    };

    const handleVideoEnd = (techId) => {
        setCurrentVideoIndex(prev => {
            const nextIndex = (prev[techId] + 1) % technologies.find(t => t.id === techId).videos.length;
            return { ...prev, [techId]: nextIndex };
        });
        setIsVideoPlaying(prev => ({ ...prev, [techId]: false }));
        setShowPlayButton(prev => ({ ...prev, [techId]: true }));
    };

    return (
        <div className="min-h-screen bg-white text-gray-800">
            <nav className="bg-gray-100 p-4 shadow-sm">
                <div className="container mx-auto">
                    <Link to="/" className="text-blue-600 font-semibold text-lg">da.inamycs</Link>
                </div>
            </nav>

            <header className="bg-gray-50 py-12">
                <div className="container mx-auto text-center px-4">
                    <h1 className="text-3xl font-semibold mb-2">Our Technology Stack</h1>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                        Pioneering environmental monitoring through an integrated approach of cutting-edge technologies.
                    </p>
                </div>
            </header>

            <main className="container mx-auto py-8 px-4">
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-3">Multistack Survey and Monitoring</h2>
                    <p className="text-base text-gray-700">
                        At da.inamycs, we employ a comprehensive multistack method for environmental survey and monitoring. 
                        By integrating LiDAR, drone scanning, soil sampling, and satellite imagery, we create a holistic view 
                        of the environment at multiple scales. This approach ensures thorough, accurate, and actionable insights 
                        for informed decision-making in environmental management and conservation.
                    </p>
                </div>

                <div className="mb-8">
                    <div className="flex flex-wrap justify-center gap-2 mb-4">
                        <button 
                            onClick={() => setActiveTab('overview')} 
                            className={`px-4 py-2 text-sm font-medium rounded-full transition-colors ${activeTab === 'overview' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                        >
                            Overview
                        </button>
                        {technologies.map(tech => (
                            <button 
                                key={tech.id}
                                onClick={() => setActiveTab(tech.id)} 
                                className={`px-4 py-2 text-sm font-medium rounded-full transition-colors ${activeTab === tech.id ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                            >
                                {tech.name}
                            </button>
                        ))}
                    </div>

                    <div className="bg-white shadow-lg rounded-lg p-4 sm:p-6">
                        {activeTab === 'overview' ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                                {technologies.map(tech => (
                                    <div key={tech.id} className="flex flex-col items-center">
                                        <h3 className="text-sm font-semibold text-center mb-2">{tech.name}</h3>
                                        <img 
                                            src={`/images/${tech.image}`} 
                                            alt={tech.name} 
                                            className="w-full h-auto rounded-lg shadow-md cursor-pointer transition-transform hover:scale-105"
                                            onClick={() => setFullscreenImage(tech.image)}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            technologies.filter(tech => tech.id === activeTab).map(tech => (
                                <div key={tech.id}>
                                    <h3 className="text-xl font-semibold mb-3">{tech.name}</h3>
                                    <p className="text-base mb-4 text-gray-700">{tech.description}</p>
                                    <ul className="list-disc pl-5 mb-6 text-sm text-gray-600">
                                        {tech.keyPoints.map((point, index) => (
                                            <li key={index} className="mb-1">{point}</li>
                                        ))}
                                    </ul>
                                    <div className="relative aspect-video mb-4 bg-gray-200 w-full md:w-3/4 mx-auto">
                                        {tech.videos.map((video, index) => (
                                            <video 
                                                key={index}
                                                ref={el => {
                                                    if (!videoRefs.current[tech.id]) {
                                                        videoRefs.current[tech.id] = [];
                                                    }
                                                    videoRefs.current[tech.id][index] = el;
                                                }}
                                                className={`absolute top-0 left-0 w-full h-full transition-opacity duration-300 ${index === currentVideoIndex[tech.id] ? 'opacity-100' : 'opacity-0'}`}
                                                style={{display: index === currentVideoIndex[tech.id] ? 'block' : 'none'}}
                                                onEnded={() => handleVideoEnd(tech.id)}
                                                playsInline
                                                onClick={() => togglePlayPause(tech.id)}
                                            >
                                                <source src={`/images/${video}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ))}
                                        {showPlayButton[tech.id] && !isVideoPlaying[tech.id] && (
                                            <button 
                                                onClick={() => togglePlayPause(tech.id)}
                                                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                    <div className="flex justify-center space-x-2">
                                        {tech.videos.map((_, index) => (
                                            <button
                                                key={index}
                                                className={`w-3 h-3 rounded-full ${index === currentVideoIndex[tech.id] ? 'bg-blue-600' : 'bg-gray-300'}`}
                                                onClick={() => setCurrentVideoIndex(prev => ({ ...prev, [tech.id]: index }))}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </main>

            <footer className="bg-gray-100 text-gray-600 py-6">
                <div className="container mx-auto text-center text-sm">
                    <p>&copy; 2024 da.inamycs. All rights reserved.</p>
                </div>
            </footer>

            {fullscreenImage && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4 animate-fade-in" onClick={() => setFullscreenImage(null)}>
                    <div className="relative bg-white rounded-lg shadow-lg w-full h-full max-w-4xl max-h-[90vh] flex items-center justify-center overflow-auto">
                        <img 
                            src={`/images/${fullscreenImage}`} 
                            alt="Fullscreen view" 
                            className="max-w-full max-h-full object-contain"
                        />
                        <button 
                            className="absolute top-2 right-2 text-gray-800 bg-white rounded-full p-2 hover:bg-gray-200 transition-colors"
                            onClick={(e) => {
                                e.stopPropagation();
                                setFullscreenImage(null);
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TechnologyPage;
