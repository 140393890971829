import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ContactPage from './ContactPage'; // Import the ContactPage component
import TechnologyPage from './TechnologyPage';

function Header() {
    return (
        <header className="bg-gray-800 text-white p-4 flex justify-between items-center w-full">
            <div className="flex items-center">
                <h1 className="text-xl font-bold">da.inamycs</h1>
            </div>
            <nav>
                <ul className="flex items-center space-x-4">
                    <li><Link to="/contact" className="hover:text-gray-300">Contact</Link></li>
                    <li><Link to="/technology" className="hover:text-gray-300">Technology</Link></li>
                    <li><button className="bg-white text-black px-4 py-2 rounded hover:bg-gray-200">Login</button></li>
                </ul>
            </nav>
        </header>
    );
}

function MainContent() {
    return (
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center z-10 text-center">
            <h2 className="text-3xl font-semibold mb-4 text-white">Sensing and Monitoring</h2>
            <p className="text-white">physical and digital territory</p>
        </div>
    );
}

function ImageComponent({ src, alt }) {
    return <img src={src} alt={alt} className="w-full h-auto" />;
}

function App() {
    return (
        <Router>
            <div className="relative min-h-screen">
                <Header />
                <Routes>
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/technology" element={<TechnologyPage />} />
                    <Route path="/" element={
                        <div className="relative w-full flex flex-col items-stretch">
                            <div className="relative z-0">
                                <ImageComponent src="/America.jpg" alt="America" />
                                <ImageComponent src="/Monsoon.jpg" alt="Monsoon" />
                                <MainContent />
                            </div>
                        </div>
                    } />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
