import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { app } from './firebase';

function ContactPage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(true);
    const videoRef = useRef(null);
    const playButtonTimeoutRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.addEventListener('play', () => setIsVideoPlaying(true));
            video.addEventListener('pause', () => setIsVideoPlaying(false));
            video.addEventListener('ended', () => {
                setIsVideoPlaying(false);
                setShowPlayButton(true);
            });
        }
        return () => {
            if (video) {
                video.removeEventListener('play', () => setIsVideoPlaying(true));
                video.removeEventListener('pause', () => setIsVideoPlaying(false));
                video.removeEventListener('ended', () => {
                    setIsVideoPlaying(false);
                    setShowPlayButton(true);
                });
            }
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Sending the following data:', formData);
        try {
            const db = getFirestore(app);
            const docRef = await addDoc(collection(db, 'contacts'), formData);
            console.log("Document written with ID: ", docRef.id);
            alert("Thank you for your message. We will get back to you soon.");
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("An error occurred. Please try again.");
        }
    };

    const togglePlayPause = () => {
        const video = videoRef.current;
        if (video) {
            if (video.paused) {
                video.play().catch(e => console.error("Error playing video:", e));
            } else {
                video.pause();
            }
        }
        setShowPlayButton(false);
        
        if (playButtonTimeoutRef.current) {
            clearTimeout(playButtonTimeoutRef.current);
        }
        
        playButtonTimeoutRef.current = setTimeout(() => {
            setShowPlayButton(!video.paused);
        }, 2000);
    };

    return (
        <div className="p-4 sm:p-8 max-w-4xl mx-auto">
            <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block mb-4">da.inamycs</Link>
            <div className="mt-8 relative aspect-video bg-gray-200">
                <video 
                    ref={videoRef}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    playsInline
                    muted
                    loop
                    onClick={togglePlayPause}
                >
                    <source src="/pacificwar.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {showPlayButton && (
                    <button 
                        onClick={togglePlayPause}
                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300"
                    >
                        {isVideoPlaying ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        )}
                    </button>
                )}
            </div>
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 mt-8">Contact Us</h1>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name" className="block font-medium text-gray-700">Name</label>
                    <input 
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required 
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block font-medium text-gray-700">Email</label>
                    <input 
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required 
                    />
                </div>
                <div>
                    <label htmlFor="message" className="block font-medium text-gray-700">Message</label>
                    <textarea 
                        id="message"
                        name="message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                    ></textarea>
                </div>
                <button type="submit" className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Send</button>
            </form>
        </div>
    );
}

export default ContactPage;
